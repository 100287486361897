<div
  #sideBar
  id="sidebar"
  *ngIf="!willShow"
  class="fixed z-20 h-full top-0 left-0 pt-16 lg:flex flex-shrink-0 flex-col w-[19rem] transition-width duration-75"
  aria-label="Sidebar">
  <div
    class="relative flex-1 flex flex-col min-h-full border-r border-app-bg bg-white pt-0 pb-20">
    <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
      <div class="flex-1 px-8 bg-white divide-y space-y-1">
        <ul class="space-y-3 pb-2">
          <li>
            <form action="#" method="GET" class="lg:hidden">
              <label for="mobile-search" class="sr-only">Search</label>
              <div class="relative">
                <div
                  class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <img
                    src="assets/svg/search.svg"
                    height=""
                    width=""
                    class="notPreview"
                    alt="search"/>
                </div>
                <input
                  type="text"
                  name="email"
                  id="mobile-search"
                  class="bg-gray-50 border-none text-gray-900 text-sm rounded-lg focus:ring-cyan-600 block w-full pl-10 p-2.5"
                  placeholder="Search" />
              </div>
            </form>
          </li>
          <li *ngIf="isAdmin()">
            <a
              routerLink="/dashboard"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/dashboard-{{
                  isActive('/dashboard') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span class="ml-3 font-semibold">Dashboard</span>
            </a>
          </li>
          <li *ngIf="isAdmin()">
            <a
              routerLink="/challan-management"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/challan-{{
                  isActive('/challan-management') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span class="ml-3 font-semibold">Challan Management</span>
            </a>
          </li>
          <li>
            <a
              routerLink="/trip-create/customerselection"
              routerLinkActive="active"
              (click)="clearSelectedTemplate()"
              [routerLinkActiveOptions]="{ exact: true }"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/trip-create-{{
                  isActive('/trip-create') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span class="ml-3 font-semibold">Create Trip</span>
            </a>
          </li>
          <li>
            <a
              (click)="clearSelectedTemplate()"
              routerLink="/manage-trip"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                height="24"
                width="24"
                loading="lazy"
                class="notPreview"
                src="assets/svg/trip-management-{{
                  isActive('/manage-trip') ? 'select' : 'deselect'
                }}.svg"
                alt=""/>
              <span class="ml-3 flex-1 font-semibold whitespace-nowrap"
              >Manage Trip</span
              >
            </a>
          </li>
          <li>
            <a
              (click)="clearSelectedTemplate()"
              routerLink="/trip-list"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                class="notPreview"
                height="24"
                width="24"
                src="assets/svg/trip-list-{{
                  isActive('/trip-list') ? 'select' : 'deselect'
                }}.svg"
                alt=""/>
              <span class="ml-3 flex-1 font-semibold whitespace-nowrap"
              >Trip List</span
              >
            </a>
          </li>
          <li *ngIf="isAdmin()">
            <a
              (click)="clearSelectedTemplate()"
              routerLink="/user-management"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/user-management-{{
                  isActive('/user-management') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span class="ml-3 flex-1 font-semibold whitespace-nowrap"
              >User Management</span
              >
            </a>
          </li>
          <li *ngIf="!isAdmin() && isCustomerAdmin()">
            <a
              (click)="clearSelectedTemplate()"
              routerLink="/user-management-customer"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/user-management-{{
                  isActive('/user-management-customer') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height=""
                width=""/>
              <span class="ml-3 flex-1 font-semibold whitespace-nowrap"
              >User Management</span
              >
            </a>
          </li>
          <li *ngIf="isAdmin()">
            <button
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="onClickProfile()"
              type="button"
              class="cursor-pointer text-base {{
                isActive('/profile') ? 'text-primary' : 'text-secondary'
              }} w-full leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
              aria-controls="dropdown-example">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/user-{{
                  isActive('/profile') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span
                class="flex-1 ms-3 text-left font-semibold rtl:text-right whitespace-nowrap"
              >Profiles</span
              >
              <i *ngIf="dropdownOpen" class="fas fa-caret-up text-gray-500"></i>
              <i
                *ngIf="!dropdownOpen"
                class="fas fa-caret-down {{
                  isActive('/profile') ? 'text-primary' : 'text-[#777777]'
                }} "></i>
            </button>
            <ul
              appGetOutsideModalClick
              id="dropdown-example"
              class="py-2 px-6 space-y-2"
              *ngIf="dropdownOpen">
              <li>
                <a
                  routerLink="/profile/customer"
                  routerLinkActive="active"
                  class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
                >Customer</a
                >
              </li>
              <li>
                <a
                  routerLink="/profile/transporter"
                  routerLinkActive="active"
                  class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
                >Transporter</a
                >
              </li>
              <li>
                <a
                  routerLink="/profile/truck"
                  routerLinkActive="active"
                  class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
                >Truck</a
                >
              </li>
              <li>
                <a
                  routerLink="/profile/driver"
                  routerLinkActive="active"
                  class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
                >Driver</a
                >
              </li>
              <li>
                <a
                  routerLink="/profile/tracker"
                  routerLinkActive="active"
                  class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group"
                >Tracker</a
                >
              </li>
            </ul>
          </li>
          <li *ngIf="isAdmin()">
            <a
              routerLink="/vehicle-tracking"
              routerLinkActive="active"
              class="cursor-pointer text-base text-secondary leading-normal font-normal rounded-lg flex items-center py-3 px-4 hover:bg-disabled group">
              <img
                loading="lazy"
                class="notPreview"
                src="assets/svg/vehicle-tracking-{{
                  isActive('/vehicle-tracking') ? 'select' : 'deselect'
                }}.svg"
                alt=""
                height="24"
                width="24"/>
              <span class="ml-3 font-semibold">Vehicle Tracking</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="fixed bottom-0 mr-0 border-app-bg">
    <div class="p-4 flex items-center justify-center w-[19rem] bg-[#FAFEFF]">
      <div class="flex flex-col items-center space-y-3">
        <img
          loading="lazy"
          class="w-2/3 notPreview"
          height="18"
          width="101"
          src="assets/svg/ecoflit.svg"
          alt="ecoflit"/>
        <span class="text-[.75rem] text-secondary"
        >© SNZ Technologies Limited {{ currentYear }}</span
        >
      </div>
    </div>
  </div>
</div>

<div
  class="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
  id="sidebarBackdrop"></div>
